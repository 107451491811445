import { defineStore } from 'pinia';
import ApiService from "~/services/ApiService";

export const useAnnouncementsStore = defineStore('announcements', {
  state () {
    return {
      announcements: [],
    };
  },
  getters: {
    getAnnouncements () {
      return this.announcements;
    }
  },
  actions: {
    async getAllAnnouncements() {
        await ApiService.getAllAnnouncements().then((response) => {
          //filter for only active announcements
          var activeAnnouncements = response.filter((announcement) => announcement.isActive && announcement.type !== 'Award');
          this.announcements = activeAnnouncements.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.publishDate) - new Date(a.publishDate);
          });
        });
      }
  },
});
